import { EMPLOYMENT_TYPES, TALENT_ROLES_CONFIG, COLORS } from '../config/enums';
import { round, unescape } from 'lodash';
import he from 'he';
import { IMG_STORAGE_LINK } from '../components/constants/Constants';
import moment from 'moment/moment';

export const pruneMobileInput = (input) => {
  const numbersOnly = input.match(/[0-9]/g);
  return numbersOnly ? numbersOnly.join('') : '';
};

export const handleTrailingSpaces = str => str && str.trimEnd();

/**
 * group messages by match id and return an object that has key match_id and value messages count
 */
export const countUnreadMessagesByMatch = (messages, user) => {
  let unreadMsgCounts = {}; // group messages by match id
  messages.map((msg) => {
    const matchId = msg.match.id;
    if (!unreadMsgCounts[matchId]) {
      unreadMsgCounts = Object.assign(unreadMsgCounts, { [matchId]: 0 });
    }
  });
  const unreadMsgs = messages.filter(msg => msg.isUnread && msg.creator_id != user.id);
  unreadMsgs && unreadMsgs.map(msg => unreadMsgCounts[msg.match.id]++);
  return unreadMsgCounts;
};

export const isPrivateStaff = user => ['Private User', 'HQ Admin'].includes(user.privateAccess);
export const getEmploymentTypes = (talent) => {
  const employmentTypes = [...EMPLOYMENT_TYPES];
  if (talent && talent.type === 'dvm-doctor-of-veterinary-medicine') {
    if (talent.isStudent) {
      employmentTypes.push('VetAssistant-Relief');
    } else {
      employmentTypes.splice(employmentTypes.indexOf('Externship'), 1);
    }
  }
  return employmentTypes;
};

export const checkIsBoostedJob = (boostDescription, jobPosting) => jobPosting && jobPosting.isBoosted && boostDescription && boostDescription.length > 0;

export const isReliefOrExternshipType = typeOfEmployment => typeOfEmployment === 'Relief' || typeOfEmployment === 'Externship';

export const isTemporaryUser = email => (email.includes('temp-user-signup--') ? true : false);

export const convertHTMLToText = (html) => {
  const decodedHTML = html ? he.decode(html) : '';
  const unescapedHTML = unescape(decodedHTML);
  const convertedText = decodedHTML.replace(/<[^>]+>/g, '');
  return convertedText;
};
export const formatPostTitle = title => (title ? title.replace(/\^|\$T/g, '') : '');
export const formatPostRole = title => (title ? title.split(/\^/g)[0] : '');

export const formatPostShift = (shift) => {
  let shiftTime = '';
  if (shift?.start_time && shift?.end_time) {
    shiftTime = `${shift.start_time}-${shift.end_time}`.replaceAll(':00', '');
  }
  const input = shift?.jobPostingTitle;
  if (!input || typeof input !== 'string') {
    return shiftTime;
  }
  const match = input.match(/\^([^\$]*)\$/);
  return match ? match[1].trim() || shiftTime : shiftTime;
};

export const shiftIcon = (shiftCat) => {
  return shiftCat === 'MORNING'
    ? `${IMG_STORAGE_LINK}shift_morning.svg`
    : shiftCat === 'AFTERNOON' ? `${IMG_STORAGE_LINK}shift_afternoon.svg` : `${IMG_STORAGE_LINK}shift_evening.svg`;
};

export const getJobRoleText = (talentType, criteriaItems) => {
  if (!talentType || !criteriaItems) return null;
  const roleObj = TALENT_ROLES_CONFIG[talentType] || {};
  const subTypeItems = criteriaItems.filter(item => item?.criteria?.name === roleObj.subKey) || [];
  let subRoles = [];
  subTypeItems.forEach((item, index) => {
    const subObj = (roleObj.subTypeList && roleObj.subTypeList.find(s => s.key === item.strValue)) || {};
    let roleText = subObj.title || subObj.category;
    // Remove the 'Specialist -' from the second speciality
    if (index > 0 && roleText.includes('Specialist -')) {
      roleText = roleText.replace('Specialist -', '');
    }
    subRoles.push(roleText);
  });
  return subRoles.sort().join(', ');
};

export const updateLocalUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const geodistance = (lat1, lon1, lat2, lon2) => {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  } else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }
};

export const findFirstNewColor = (colorsUsed) => {
  const index = colorsUsed.indexOf(false);
  if (index !== -1) {
    return index;
  } else {
    return 0;
  }
};

export const updateColorsUsed = (color, colorsUsed) => {
  const index = COLORS.indexOf(color);
  let colorsUsedArray = colorsUsed;
  colorsUsedArray[index] = true;
  if (colorsUsedArray.indexOf(false) === -1) {
    colorsUsedArray = new Array(COLORS.length).fill(false);
  }
  return {
    colorsUsed: colorsUsedArray,
    nextColor: findFirstNewColor(colorsUsedArray),
  };
};

export const getTimeCategory = (time) => {
  const hour = time.hours();
  if (hour >= 6 && hour < 12) {
    return 'MORNING';
  } else if (hour >= 12 && hour < 18) {
    return 'AFTERNOON';
  } else {
    return 'NIGHT';
  }
};
